@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #0c1850;
  --secondary: #a7f3d0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: "Righteous", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  position: fixed;
  top: 80px;
  left: 20px;
  width: max-content;
  z-index: 5;
}

.nav-links {
  width: 140px;
}

@media screen and (max-width: 1200px) {
  .nav-links {
    width: 120px;
  }
}

@media screen and (max-width: 767px) {
  .nav-links {
    display: none;
  }
}

.nav-link {
  cursor: pointer;
  margin: 4px 0;
  width: 100%;
  background-color: #2681bd;
  border-radius: 4px;
}

.nav-link-line {
  height: 3px;
  width: 50%;
  background-color: white;
}

.nav-link-text {
  font-family: "Righteous";
  font-size: 20px;
}

.main-image{
  background-image: url("./assets/images/other/newCottageImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30px;
  height: 100vh;
  width: 100vw;
  width: 100%;
}

.main-left {
  width: 35%;
  height: 80vh;
  padding: 10% 0;
  margin-bottom: 2px;
}

.main-buttons {
  margin-bottom: 15%;
}

.main-button {
  background-color: white;
  border-radius: 8px;
  color: #021569;
  padding: 10px;
  margin: 20px 0;
  width: 50%;
}

.relief-button {
  background-color: rgb(255, 255, 255);
}

.relief-form-name {
  width: 44%;
  text-align: center;
  height: 50px;
  font-size: 26px;
}

.relief-form-phone {
  width: 90%;
  text-align: center;
  height: 50px;
  font-size: 26px;
}

.relief-form-address{
  width: 90%;
  height: 50px;
  font-size: 22px;
}

.address-list {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#autocomplete-result {
  width: 90%;
  box-shadow: 10px 5px 5px gray;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  border: solid 1px gray;
  background: lightgray;
  text-align: center;
  padding: 6px 6px 6px;
}

.relief-form-message {
  width: 90%;
  height: 80px;
  font-size: 24px;
}

.relief-form-submit {
  width: 90%;
  background-color: #0099ff;
  color: var(--primary);
  text-align: center;
  padding: 15px 0;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
}

.relief-form-submit:hover {
    color: #ffffff;
    background: rgb(0, 255, 34);
    cursor: pointer;
}

.image-container {
  height: 300px; /* set a height for the div */
  width: max-content;
}

#footer {
  margin-top: 20px;
  padding: 20px;
  min-height: 20vh;
}

#footer h1 {
  font-size: 24px;
}

#footer h2 {
  font-size: 20px;
}

#footer a:hover {
  color: gold;
}

.footer-logo {
  max-width: 200px;
  max-height: 200px;
}

.submit {
  color: #021569;
  cursor: pointer;
}

.textbox {
  color: #021569;
}

.team-header {
  font-size: 80px;
  margin-bottom: 40px;
}

.team {
    width: 80%;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-around; */
}

@media screen and (max-width: 510px) {
  .team {
    width: 100%;
  }
}


.team-member {
    margin: 20px;
    background: white;
    color: var(--primary);
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    border: 24px solid white;
    border-radius: 12px;
    transition: box-shadow 0.3s ease-in-out;
}

.team-member:hover {
  box-shadow: 0 0 10px #FFFFFF, 0 0 20px #FFFFFF;
  text-shadow: 0 0 10px #FFFFFF, 0 0 20px #FFFFFF;
}

.team-member img {
  width: 100%;
}

.member-bio {
    padding: 25px 25px 5px;
    line-height: 1.3;
    height: fit-content;
}

.member-bio h3 {
    font-size: 36px;
}

.member-bio h4 {
    font-weight: lighter;
    font-size: 22px;
    margin-bottom: 15px;
}

.member-bio p {
    font-size: 17px;
}

.member-role {
  color: lightcoral;
}

#Contact {
  background-color: var(--primary);
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;
  padding: 0 150px;
}



.contact {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.contact-left {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.contact-map {
  display: flex;
  justify-content: center;
  align-items: end;
}

.contact-map iframe {
  border: 4px solid rgb(27, 21, 116);
  border-radius: 4px;
  width: 75%;
  height: 80%;
}

.contact-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact-info > * {
    flex: 1;
    margin: 15px;
}

.contact-info h3 {
    color: rgb(255, 255, 255);
    font-size: 42px;
}

.contact-info p, .contact-info address {
    margin: 20px 0;
    line-height: 1.5;
    font-size: 20px;
    font-style: normal;
}

.contact-form input, .contact-form textarea {
    border: 1px solid rgb(0, 0, 0);
    display: block;
    padding: 7px 15px;
    font-size: 22px;
    color: var(--primary);
    width: 100%;
    margin-bottom: 15px;
    margin-top: 5px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.75);
}

.contact-form textarea {
  font-size: 18px;
}

.contact-form input:focus, .contact-form textarea:focus {
    background-color: rgba(255, 255, 255, 1);
}

.contact-form input:not(:placeholder-shown):valid, .contact-form textarea:not(:placeholder-shown):valid {
    background-color:#ffffff;
}

.contact-form button {
  width: 100%;
  border: none;
  color: var(--primary);
  text-align: center;
  padding: 15px 0;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
}

.avatar {
  max-height: 520px;
  /* min-height: fit-content; */
}

.contact-form button {
  background-color: white;
  color: var(--primary);
}

.contact-form button:hover {
    color: white;
    background: rgb(0, 255, 51);
}

.contact-info a {
    color: #0099ff;
}

@media screen and (min-width: 1980px) {
  #Main section {
    margin-top: 5vh;
  }
}

@media screen and (min-width: 2300px) {
  #Main section {
    margin-top: 10vh;
  }

  #Main section h1 {
    font-size: 72px;
  }

  #Main section h2 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-form input {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .scrolled {
  background-color: var(--primary);
  height: 270px !important;
}

  .svg-img-2 {
    display: none;
    visibility: hidden;
  }

  #Main {
    margin: 0;
    justify-content: center;
  }

  .main-image {
    background-position: center;
  }
}

@media screen and (max-width: 1023px) {
  .svg-img-2 {
    height: 525px;
  }

  .relief-form-name {
    width: 29.5%;
  }

  .relief-form-address {
    font-size: 18px;
    width: 60%;
  }

  #autocomplete-result {
    width: 60%;
  }

  .relief-form-message, .relief-form-submit, .relief-form-phone {
    width: 60%;
  }

  #Team {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  #Contact {
    height: fit-content;
  }

  .contact-info {
    flex-direction: column;
  }

  .contact-left {
    text-align: center;
  }

  .contact-map iframe {
    width: 100%;
    height: 20vh;
  }
}

@media screen and (min-width: 768px) {
  .relief-form-address {
    font-size: 22px;
  }

  .main-image {
    background-position: center;
  }

  .scrolled {
  background-color: var(--primary);
  height: 210px;
}
}

@media screen and (max-width: 767px) {
  .svg-img-2 {
    display: none;
    visibility: hidden;
  }

  .main-image {
    background-position: center;
  }

  #Main {
    margin-top: 0;
    justify-content: center;
  }

  .relief-form-name {
    width: 36.5%;
  }

  .relief-form-phone, .relief-form-address, .relief-form-message, .relief-form-submit {
    width: 75%;
  }

  .relief-form-address {
    font-size: 1rem;
  }

  #autocomplete-result {
    width: 75%;
  }

  #Team {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  #Team {
    padding-top: 20px;
    padding-bottom: 0;
  }

.contact-form input, .contact-form textarea {
    font-size: 18px;
}

.relief-form-address {
  font-size: 14px;
}

.mission {
  margin: 40px;
}
}

.reno-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-house {
  width: 65%;
  height: 170px;
  background-image: url('./assets/images/other/00.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-y: 0%;
  background-position-x: 90%;  
  border-radius: 6px;
  cursor: pointer;
}

.top-house-active {
  filter: grayscale(0);
}

.top-mid-house {
  margin: 8px 0px 0px 0px;
  width: 65%;
  height: 170px;
  background-image: url('./assets/images/other/01.png');
  background-size: auto 100%;
  background-position-y: 15%;
  background-position-x: 90%;
  /* background-position: 0px -170px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.top-mid-house-active {
  filter: grayscale(0);
}

.mid-house {
  margin: 8px 0px 0px 0px;
  width: 65%;
  height: 170px;
  background-image: url('./assets/images/other/02.png');
  background-size: auto 100%;
  background-position-y: 30%;
  background-position-x: 90%;
  /* background-position: 0px -340px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}


.mid-house-active {
  filter: grayscale(0);
}

.low-mid-house {
  margin: 8px 0px 0px 0px;
  width: 65%;
  height: 170px;
  background-image: url('./assets/images/other/03.png');
  background-size: auto 100%;
  /* background-position: 0px -510px; */
  background-position-y: 45%;
  background-position-x: 90%;
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.low-mid-house-active {
  filter: grayscale(0);
}

.bottom-house {
  margin: 8px 0px 0px 0px;
  width: 65%;
  height: 170px;
  background-image: url('./assets/images/other/04.png');
  background-size: auto 100%;
  background-position-y: 60%;
  background-position-x: 90%;
  border-radius: 6px;
  cursor: pointer;
}


.bottom-house-active {
  filter: grayscale(0);
}

.image-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 65px;
  /* margin-left: 20px; */
  cursor: pointer;
  /* background-color: white; */
  backdrop-filter: blur(12px);
  color: white;
  width: fit-content;
  padding: 4px;
}

.text-container {
  background-color: white;
  width: 65%;
  color: var(--primary);
}

.text-container-header {
  border-bottom: 6px solid var(--primary);
  width: fit-content;
}

.text-container video {
  height: 200px;
  width: 400px;
}

.text-container img {
  height: 400px;
  width: 400px;
}

@media (max-width: 768px) {
  .top-house {
  width: 80%;
  height: 70px;
  background-image: url('./assets/images/other/00.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.top-mid-house {
  margin: 8px 0px 0px 0px;
  width: 80%;
  height: 70px;
  background-image: url('./assets/images/other/01.png');
  background-size:  100% 100%;
  /* background-position: 0px -170px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.mid-house {
  margin: 8px 0px 0px 0px;
  width: 80%;
  height: 70px;
  background-image: url('./assets/images/other/02.png');
  background-size: 100% 100%;
  /* background-position: 0px -340px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.low-mid-house {
  margin: 8px 0px 0px 0px;
  width: 80%;
  height: 70px;
  background-image: url('./assets/images/other/03.png');
  background-size: 100% 100%;
  /* background-position: 0px -510px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.bottom-house {
  margin: 8px 0px 0px 0px;
  width: 80%;
  height: 70px;
  background-image: url('./assets/images/other/04.png');
  background-size: 100% 100%;
  /* background-position: 0px -680px; */
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
}

.image-text {
  font-size: 1.2rem;
  margin-top: 0;
  margin-left: 0;
  position: relative;
  top: 10px;
  left: 10px;
}

.text-container {
  width: 80%;
}

.text-container-description {
  font-size: 16px;
}

.text-container video {
  height: 200px;
  width: 200px;
}

.text-container img {
  height: 200px;
  width: 200px;
}
}

.check {
  background-image: url(./assets/images/other/star-symbol-icon.png);
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: left center;
  padding-left: 40px;
}

@media screen and (max-width: 650px) {
  #Contact {
    padding: 0px 0px 0px 0px;
  }
}